<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="5"
      class="elevation-2 rounded row-pointer"
      no-data-text="ไม่มีข้อมูล"
      :footer-props="{
        'items-per-page-text': 'จำนวนแถว',
      }"
      :loading="isLoading"
      @click:row="clickMaintenance"
    ></v-data-table>
    <EditMaintenanceContractDialog
      v-model="editMaintenanceContractDialog"
      :readonly="true"
      :maintenanceInfo="maintenanceInfo"
      @close="editMaintenanceContractDialog = false"
    />
  </div>
</template>

<script>
import utils from '@/assets/js/utils'

export default {
  components: {
    EditMaintenanceContractDialog: () =>
      import(
        '@/components/maintenance/dialog/EditMaintenanceContractDialog.vue'
      )
  },
  data: () => ({
    headers: [
      {
        text: 'ปีงบประมาณ',
        align: 'center',
        sortable: false,
        value: 'budget_year',
        class: 'primary rounded-tl white--text'
      },
      {
        text: 'เลขที่สัญญา',
        align: 'center',
        sortable: false,
        value: 'contract_no',
        class: 'primary white--text'
      },
      {
        text: 'ผู้รับจ้าง',
        align: 'center',
        sortable: false,
        value: 'company_contract',
        class: 'primary white--text'
      },
      {
        text: 'เริ่มสัญญา',
        align: 'center',
        sortable: false,
        value: 'contract_date_start_label',
        class: 'primary white--text'
      },
      {
        text: 'สิ้นสุดสัญญา',
        align: 'center',
        sortable: false,
        value: 'contract_date_end_label',
        class: 'primary white--text rounded-tr'
      }
    ],
    items: [],
    isLoading: false,
    maintenanceInfo: {},

    editMaintenanceContractDialog: false
  }),
  mounted () {
    this.getMaintenanceContractList({
      bridge_ids: [this.$route.query.id]
    })
  },
  methods: {
    async getMaintenanceContractList (filter) {
      this.isLoading = true
      this.filterMaintenanceContractDialog = false
      const items = await this.$store.dispatch(
        'maintenance/getMaintenanceContractList',
        { filter }
      )
      for (const item of items) {
        item.budget_year = parseInt(item.budget_year) + 543
        item.contract_date_start_label = this.stringToDate(
          item.contract_date_start
        )
        item.contract_date_end_label = this.stringToDate(item.contract_date_end)
        item.bridge_ids = item.bridge_ids.map((bridge) => {
          return parseInt(bridge)
        })
      }
      this.items = items
      this.isLoading = false
    },
    stringToDate (string) {
      const date = utils.stringToDate(string)
      return date.toLocaleString('th-TH', { dateStyle: 'medium' })
    },
    clickMaintenance (maintenance) {
      this.isAdd = false
      this.maintenanceInfo = maintenance
      this.editMaintenanceContractDialog = true
    }
  }
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
